
const OPENAI_API_KEY: string = process.env.REACT_APP_OPENAI_API_KEY || ''; 
const LOCAL_RELAY_SERVER_URL: string =
  process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';

import { useEffect, useRef, useCallback, useState } from 'react';

import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { instructions } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';

import { X, Edit, Zap, ArrowUp, ArrowDown } from 'react-feather';
import { FaMicrophone as Mic, FaMicrophoneSlash as MicOff } from 'react-icons/fa';
import { Button } from '../components/button/Button';
import { Toggle } from '../components/toggle/Toggle';
import { Map } from '../components/Map';

import './ConsolePage.scss';
import { isJsxOpeningLikeElement } from 'typescript';
import { useNavigate } from 'react-router-dom';

export function ConsolePage() {
 
  const navigate = useNavigate(); // Hook for navigation, ensure it's used

  const goToManualRecording = () => {
    navigate('/ManualRecordingPage'); // Navigate to the Manual Recording page
  };
  
  const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)({
    sampleRate: 24000 });


  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({ url: LOCAL_RELAY_SERVER_URL })
  );
  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   * - coords, marker are for get_weather() function
   */

  const [items, setItems] = useState<ItemType[]>([]);
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});
  //const [transcription, setTranscription] = useState<string | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [turnEndType, setTurnEndType] = useState('server_vad');
  

  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const startTimeRef = useRef<string>(new Date().toISOString());
  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Set state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setItems(client.conversation.getItems());

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    await client.connect();
    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: `Hello!`,
        // text: `For testing purposes, I want you to list ten car brands. Number each item, e.g. "one (or whatever number you are one): the item name".`
      },
    ]);

    if (client.getTurnDetectionType() === 'server_vad') {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, []);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setItems([]);
    setMemoryKv({});


    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    wavStreamPlayer.interrupt();
  }, []);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);


  const startRecording_push = async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording_push = async () => {
    setIsRecording(false);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  };


  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  // `changeTurnEndType` function
const changeTurnEndType = async (value: string) => {
  const client = clientRef.current;
  const wavRecorder = wavRecorderRef.current;

  // If switching from REALTIME to another mode, pause recording if currently recording
  if (value === 'none' && wavRecorder.getStatus() === 'recording') {
    await wavRecorder.pause();
  }

  // Update session based on the selected mode
  client.updateSession({
    turn_detection: value === 'server_vad' ? { type: 'server_vad' } : null,
  });

  if (value === 'server_vad' && client.isConnected()) {
    // Enable push-to-talk for REALTIME mode and start recording
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    setCanPushToTalk(true);
  } else if (value === 'manual') {
    // Disable push-to-talk in Manual mode
    goToManualRecording(); 
    setCanPushToTalk(false);
    
  } else {
    // For other cases (e.g., 'none'), disable push-to-talk
    setCanPushToTalk(false);
  }
};
  
  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // Set instructions
    client.updateSession({ instructions: instructions });
    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });


    // handle realtime events from client + server for event logging
    
    client.on('conversation.updated', async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);
  

  /**
   * Render the application
   */
  return (
    <div data-component="ConsolePage">
      <div className="content-top">
        <div className="content-title">
          <img src="/owl.png" />
          <h1>Real Time Speech Assistance</h1>
          
        </div>
      </div>
      <div className="instructions">
        <div className="head2"> 
      <h2> Welcome to Oratory Owl </h2>
      </div>
{/*   <div className="step">
    <strong>Step 1:</strong> Begin by uploading your therapy reports in <strong>Reports</strong>.
  </div> */}
{/*   <div className="step">
    <strong>Step 2:</strong> In <strong>Records</strong>,
    <ul>
      <li>Directly record voice records and submit,</li>
      <li>or</li>
      <li>Record your conversations and upload them.</li>
    </ul>
  </div> */}
</div>

      <div className="content-block conversation">
        <div className="content-block-title"></div>
        <div></div>
        <div className="content-block-body" data-conversation-content>
          {!items.length && ``}
          {items.map((conversationItem) => {
            return (
              <div className="conversation-item" key={conversationItem.id}>
                <div className={`speaker ${conversationItem.role || ''}`}>
                  <div>
                    {(conversationItem.role || conversationItem.type).replaceAll('_', ' ')}
                  </div>
                  <div
                    className="close"
                    onClick={() => deleteConversationItem(conversationItem.id)}
                  >
                    <X />
                  </div>
                </div>
                <div className={`speaker-content`}>
                  {/* tool response */}
                  {conversationItem.type === 'function_call_output' && (
                    <div>{conversationItem.formatted.output}</div>
                  )}
                  {/* tool call */}
                  {!!conversationItem.formatted.tool && (
                    <div>
                      {conversationItem.formatted.tool.name}(
                      {conversationItem.formatted.tool.arguments})
                    </div>
                  )}
                  {!conversationItem.formatted.tool && conversationItem.role === 'user' && (
                    <div>
                      {conversationItem.formatted.transcript ||
                        (conversationItem.formatted.audio?.length
                          ? '(awaiting transcript)'
                          : conversationItem.formatted.text || '(item sent)')}
                    </div>
                  )}
                  {!conversationItem.formatted.tool && conversationItem.role === 'assistant' && (
                    <div>
                      {conversationItem.formatted.transcript ||
                        conversationItem.formatted.text ||
                        '(truncated)'}
                    </div>
                  )}
                  {conversationItem.formatted.file && (
                    <audio
                      src={conversationItem.formatted.file.url}
                      controls
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="content-actions">
      <Toggle
       defaultValue={false}
       labels={['RealTime', 'Manual Record']}
       values={['server_vad', 'manual']} // Ensure Toggle values match the modes handled in `changeTurnEndType`
       onChange={(_, value) => changeTurnEndType(value)}
      />
        <div className="spacer" />
        {isConnected && canPushToTalk && (
    <>
      {/* Render the Push to Talk button in RealTime mode */}
      {turnEndType === 'server_vad' && (
	       <Button
        label={isRecording ? 'Release to Send' : 'Push to Talk'}
        iconPosition={isRecording ? 'end' : 'start'}
        icon={isRecording ? Mic : MicOff} // Assume `Mic` and `MicOff` are imported icons
        buttonStyle={isRecording ? 'alert' : 'regular'}
        disabled={!isConnected || !canPushToTalk}
        onClick={isRecording ? stopRecording_push : startRecording_push}
      />
      //  <Button
        //  label={isRecording ? 'Release to Send' : 'Push to Talk'}
          //buttonStyle={isRecording ? 'alert' : 'regular'}
          //disabled={!isConnected || !canPushToTalk}
          //onMouseDown={startRecording_push}
          //onMouseUp={stopRecording_push}
        ///>
      )}
      {/* Render the Start/Stop button in Manual mode */}
      {/* {turnEndType === 'manual' && (
            <Button
              label="Go to Manual Recording"
              buttonStyle="regular"
              onClick={goToManualRecording} // Correctly reference the function here
            />
      )} */}
    </>

        )}
        <div className="spacer" />
        <Button
          label={isConnected ? 'disconnect' : 'connect'}
          iconPosition={isConnected ? 'end' : 'start'}
          icon={isConnected ? X : Zap}
          buttonStyle={isConnected ? 'regular' : 'action'}
          onClick={
            isConnected ? disconnectConversation : connectConversation
          }
        />
      </div>
    </div>
  );
  
  
}
