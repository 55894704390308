// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-component=App] {
  height: 100%;
  width: 100%;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;AACF","sourcesContent":["[data-component='App'] {\r\n  height: 100%;\r\n  width: 100%;\r\n  position: relative;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
