// import { useEffect } from "react";

// export const ManualRecordingPage = () => {
//   useEffect(() => {
//     console.log("Redirecting to microservice...");
//     const microserviceUrl = "http://127.0.0.1:8000/";
//     window.location.href = microserviceUrl; // Trigger redirection
//   }, []);

//   return <div>Redirecting...</div>;
// };

// export default ManualRecordingPage;

// import { useEffect } from "react";

// export const ManualRecordingPage = () => {
//   useEffect(() => {
//     console.log("Redirecting to microservice...");
//     try {
//       const microserviceUrl = "http://127.0.0.1:8000/";
//       console.log("Redirecting to:", microserviceUrl);
//       window.location.replace(microserviceUrl); // Trigger redirection
//     } catch (error) {
//       console.error("Redirection failed:", error);
//     }
//   }, []);

//   return <div>Redirecting...</div>;
// };

// export default ManualRecordingPage;
import { useEffect } from "react";

export const ManualRecordingPage = () => {
  useEffect(() => {
    console.log("Attempting to redirect...");
    const microserviceUrl = "http://127.0.0.1:8000/";
    window.location.href = microserviceUrl; // Trigger redirection
  }, []);

  return (
    <div>
    </div>
  );
};

export default ManualRecordingPage;


