// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-component=Toggle] {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  overflow: hidden;
  font-size: 24px;
  background-color: #ececf1;
  color: #101010;
  height: 40px;
  width: 370px;
  border-radius: 1000px;
}
[data-component=Toggle]:hover {
  background-color: #d8d8d8;
}
[data-component=Toggle] div.label {
  position: relative;
  color: #666;
  transition: color 0.1s ease-in-out;
  padding: 0px 16px;
  z-index: 2;
  -webkit-user-select: none;
          user-select: none;
}
[data-component=Toggle] div.label.right {
  margin-left: -8px;
}
[data-component=Toggle] .toggle-background {
  background-color: #101010;
  position: absolute;
  top: 0px;
  left: 0px;
  width: auto;
  bottom: 0px;
  z-index: 1;
  border-radius: 1000px;
  transition: left 0.1s ease-in-out, width 0.1s ease-in-out;
}
[data-component=Toggle][data-enabled=true] div.label.right {
  color: #fff;
}
[data-component=Toggle][data-enabled=false] div.label.left {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/toggle/Toggle.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EAEA,yBAAA;EACA,cAAA;EACA,YAAA;EACA,YAAA;EACA,qBAAA;AADF;AAIE;EACE,yBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,WAAA;EACA,kCAAA;EACA,iBAAA;EACA,UAAA;EACA,yBAAA;UAAA,iBAAA;AAHJ;AAME;EACE,iBAAA;AAJJ;AAOE;EACE,yBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,UAAA;EACA,qBAAA;EACA,yDAAA;AALJ;AASI;EACE,WAAA;AAPN;AAYI;EACE,WAAA;AAVN","sourcesContent":["\r\n[data-component='Toggle'] {\r\n  position: relative;\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 8px;\r\n  cursor: pointer;\r\n  overflow: hidden;\r\n  font-size: 24px;\r\n\r\n  background-color: #ececf1;\r\n  color: #101010;\r\n  height: 40px;\r\n  width: 370px;\r\n  border-radius: 1000px;\r\n  \r\n\r\n  &:hover {\r\n    background-color: #d8d8d8;\r\n  }\r\n\r\n  div.label {\r\n    position: relative;\r\n    color: #666;\r\n    transition: color 0.1s ease-in-out;\r\n    padding: 0px 16px;\r\n    z-index: 2;\r\n    user-select: none;\r\n  }\r\n\r\n  div.label.right {\r\n    margin-left: -8px;\r\n  }\r\n\r\n  .toggle-background {\r\n    background-color: #101010;\r\n    position: absolute;\r\n    top: 0px;\r\n    left: 0px;\r\n    width: auto;\r\n    bottom: 0px;\r\n    z-index: 1;\r\n    border-radius: 1000px;\r\n    transition: left 0.1s ease-in-out, width 0.1s ease-in-out;\r\n  }\r\n\r\n  &[data-enabled='true'] {\r\n    div.label.right {\r\n      color: #fff;\r\n    }\r\n  }\r\n\r\n  &[data-enabled='false'] {\r\n    div.label.left {\r\n      color: #fff;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
