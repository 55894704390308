//import { ConsolePage } from './pages/ConsolePage';
//import './App.scss';

//function App() {
 // return (
    //<div data-component="App">
     // <ConsolePage />
    //</div>
 // );
//}

//export default App;

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConsolePage } from './pages/ConsolePage';
import { ManualRecordingPage } from './pages/ManualRecordingPage';
import './App.scss';

function App() {
  return (
    <div data-component="App">
      <Router>
      <Routes>
        <Route path="/" element={<ConsolePage />} />
        <Route path="/ManualRecordingPage" element={<ManualRecordingPage />} />
      </Routes>
    </Router>
    </div>
    
  );
}

export default App;
